export default {
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Català"])},
  "Docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentació"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tancar sesió"])},
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar-se"])},
  "Log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesió"])},
  "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instruccions"])},
  "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processar"])},
  "Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcions"])},
  "Advanced options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcions avançades"])},
  "Process buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processar edificis"])},
  "Process addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processar adreces"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estat"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informació"])},
  "admin-boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["límits administratius"])},
  "project-size-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mida del projecte és adequada per processar."])},
  "project-size-ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El projecte és molt gran. Es recomana seleccionar un districte o un barri. Si no estan definits a OSM, creeu relacions de ", _interpolate(_list(0)), " amb nivell 9 o 10."])},
  "Source date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de publicació"])},
  "Feature count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de característiques"])},
  "Pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscines"])},
  "Building parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parts d'edificis"])},
  "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar"])},
  "Review done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisions completades"])},
  "Review street names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar carrerer"])},
  "review_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisa els noms de les vies/llocs mostrats en el panell seguint aquestes ", _interpolate(_list(0)), ". La columna esquerra mostra el nom de la via/lloc a Cadastre, la dreta la conversió proposada que s'usarà en reprocessar. Edita les que no siguin correctes o valida (", _interpolate(_list(1)), ") les que si. Deixa-la en blanc (", _interpolate(_list(2)), ") per a excloure totes les adreces d'aquesta via. Quan acabis continua al següent pas."])},
  "Reprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocessar"])},
  "Check fixmes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corregir errors"])},
  "Locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquejat per"])},
  "Edited by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editat per"])},
  "fixmes left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Falta ", _interpolate(_named("n")), " fixme."]), _normalize(["Falten ", _interpolate(_named("n")), " fixmes."])])},
  "highways left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Falta ", _interpolate(_named("n")), " carrer."]), _normalize(["Falten ", _interpolate(_named("n")), " carrers."])])},
  "reviewed_hgw": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisades ", _interpolate(_list(0)), " de ", _interpolate(_list(1)), " carrers."])},
  "reviewed_files": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisats ", _interpolate(_list(0)), " de ", _interpolate(_list(1)), " fitxers."])},
  "fixme_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Baixa't (", _interpolate(_named("download")), ") els arxius mostrats en el panell. Edita'ls amb JOSM seguint aquestes ", _interpolate(_named("link")), ". Guarda els resultats i puja els arxius corregits (", _interpolate(_named("upload")), "). Revisa els edificis que tinguin etiquetes \"fixme\" i elimina-les. No cal pujar les dades a OSM, sinó aquí."])},
  "welldone1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben fet. Confirma per a continuar."])},
  "welldone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben fet. Reprocessa per a continuar."])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "finish_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Procés de ", _interpolate(_list(0)), " finalitzat"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
  "Task manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestor de tasques"])},
  "done_msg1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Crea un nou projecte en el ", _interpolate(_list(0)), " usant l'arxiu ", _interpolate(_list(1)), "."])},
  "done_msg2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Completa els camps necessaris copiant-los (", _interpolate(_list(0)), ") de la plantilla mostrada al panell."])},
  "done_msg3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Veure el ", _interpolate(_list(0)), "."])},
  "process result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultat del procés"])},
  "You can also": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["També pots"])},
  "Buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificis"])},
  "Addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adreces"])},
  "Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar"])},
  "export_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixa els resultats del treball com a còpia de seguretat."])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietari/a"])},
  "delete_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar el procés (compte, acció no reversible)."])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xat"])},
  "Copy to clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar al portapapers"])},
  "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camp"])},
  "Spanish (es)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espanyol (es)"])},
  "English (en)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglès (en)"])},
  "Name of the project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom del projecte"])},
  "Short description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripció breu"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripció"])},
  "Changeset comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentari del conjunt de canvis"])},
  "Detailed instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruccions detallades"])},
  "Per task instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruccions addicionals per aquesta tasca"])},
  "Select only downloaded files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona només els arxius baixats"])},
  "Drop them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixa'ls anar"])},
  "Drop here or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrossega els arxius corregits aquí o "])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selecciona'ls"])},
  "Select reviewed file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar arxiu corregit"])},
  "Sorry, no matching option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho sentim, opció no trobada"])},
  "Select the province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la província..."])},
  "Select the municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el municipi..."])},
  "Select the subarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la divisió..."])},
  "admin boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisions administratives"])},
  "select_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Selecciona una província i el municipi a processar. Si existeixen ", _interpolate(_list(0)), " del municipi (admin_level = 9 - districtes o admin_level = 10 – barris), també pots seleccionar una."])},
  "Log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre"])},
  "The process is locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El procés està bloquejat per"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
  "Delete filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar filtre"])},
  "Name in Cadastre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom a Cadastre"])},
  "Conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversió"])},
  "joined": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " ha entrat a ", _interpolate(_named("room"))])},
  "leave": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " ha sortit de ", _interpolate(_named("room"))])},
  "Procesando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processant"])},
  "Pendiente de revisar direcciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendent de revisar adreces"])},
  "Pendiente de revisar problemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendent de revisar problemes"])},
  "Proceso finalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procés finalitzat"])},
  "No procesado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No processat"])},
  "Terminó con error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha acabat amb error"])},
  "Autorización denegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorització denegada"])},
  "No es un archivo gzip válido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No és un arxiu gzip vàlid"])},
  "Sólo archivos de tareas existentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Només arxius de tasques existents"])},
  "No se pudo eliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es va poder eliminar"])},
  "Proceso eliminado correctamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procés eliminat correctament"])},
  "Proceso no encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procés no trobat"])},
  "create_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " inicia el procés de ", _interpolate(_named("room"))])},
  "restart_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " reinicia el procés de ", _interpolate(_named("room"))])},
  "delete_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " elimina el procés de ", _interpolate(_named("room"))])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fet"])},
  "view_in_osm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure a OpenStreetMap"])},
  "Validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
  "Undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desfer"])},
  "parcel_parts_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de parts d'edifici per començar a afegir parcel·les per a tasques."])},
  "parcel_dist_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distància en metres per afegir parcel·les per a tasques."])},
  "between_val": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El valor ha d'estar entre ", _interpolate(_list(0)), " i ", _interpolate(_list(1)), "."])},
  "add_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear projecte"])},
  "Share project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir projecte"])},
  "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar"])},
  "Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
  "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyecto"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuari"])},
  "Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasques"])},
  "parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parts"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponible"])},
  "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["executant-se"])},
  "DONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acabat"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["carrerer"])},
  "FIXME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corregir"])},
  "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error"])},
  "hero title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importació del Cadastre Espanyol a OpenStreetMap"])},
  "hero subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Eina en línia per convertir edificis i adreces dels ", _interpolate(_list(0)), " del Cadastre a fitxers ", _interpolate(_list(1)), "."])},
  "Servicios ATOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveis ATOM"])},
  "hero content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les importacions i edicions automatitzades han de ser dutes a terme per persones amb experiència i prou coneixement de la manera com la comunitat OpenStreetMap crea mapes i amb una acurada planificació i consulta amb la comunitat local corresponent."])},
  "hero content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Consulta la ", _interpolate(_list(0)), ". Les importacions o edicions automatitzades que no segueixin aquestes directrius es poden revertir!"])},
  "import guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guia d'importació"])},
  "docview title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestió de projectes"])},
  "docview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquesta eina en línia serveix per convertir dades d'edificis i adreces de cadastre al format d'OSM. La conversió es realitza per zones, generalment un municipi i el resultat és un projecte d'importació disponible perquè diferents usuaris puguin portar a terme la importació de forma col·laborativa."])},
  "docview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["És interessant que abans de crear el teu primer projecte coneguis com és el procés de participació en els que ja estan publicats. Anima't a col·laborar-hi seguint la ", _interpolate(_list(0)), "."])},
  "docview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per crear un projecte d'una zona no publicada cal fer aquests passos:"])},
  "docview login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre"])},
  "docview select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar zona"])},
  "docview process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processar"])},
  "docview review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar la guia de carrers"])},
  "docview fixme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corregir errors"])},
  "docview publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicació"])},
  "docview need help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necessites ajuda?"])},
  "docview need help explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dubtis a contactar a través de qualsevol d'aquests mitjans:"])},
  "docview mail list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llista de correu Talk-ca"])},
  "docview telegram matrix": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Canal de ", _interpolate(_list(0)), " / ", _interpolate(_list(1))])},
  "docview forum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fòrum ", _interpolate(_list(0))])},
  "docview need help end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allà trobaràs altres usuaris disposats a resoldre els teus dubtes o col·laborar en allò necessari."])},
  "docselectview admin level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcionalment, seleccioneu una divisió administrativa."])},
  "docselectview check size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprova la mida del projecte."])},
  "docselectview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguiu aquestes instruccions per seleccionar la zona a processar:"])},
  "docselectview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Després de seleccionar un municipi, al panell d'informació tindràs dades que et permeten fer-te una idea de la mida que tindrà el projecte. Com més edificis o adreces més gran serà la feina. Interessa que un projecte no sigui gaire gran, especialment si és la primera vegada que en crees un. Com més gran sigui, més temps serà necessari per a la revisió prèvia a la seva publicació i per completar la importació un cop publicat."])},
  "docselectview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com a regla general, podem dir que un projecte és massa gran si el nombre de parts d'edifici és superior a 10.000. El programa us alertarà en cas de superar aquesta xifra. Si el projecte és gran i no vols començar per cap altre municipi menor, intenta seleccionar una divisió administrativa."])},
  "docselectview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Els municipis se solen dividir en entitats administratives menors. A OSM, els municipis tenen l'etiqueta ", _interpolate(_list(0)), ". Per sota, amb ", _interpolate(_list(1)), ", hi ha els districtes (el nom pot canviar segons les peculiaritats de cada territori). Dins de cada districte existiran barris (o l'equivalent a la teva zona), amb ", _interpolate(_list(2)), "."])},
  "docselectview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si aquests límits administratius estan definits a OSM, apareixeran al selector de divisió administrativa sota el selector de municipi. Selecciona un barri que tingui la mida adequada per processar."])},
  "docselectview content6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si els ", _interpolate(_list(0)), " no estan definits a OSM, és recomanable crear els que necessitis. Si utilitzareu dades de tercers per dibuixar aquests límits i no estan publicats amb una llicència compatible caldrà sol·licitar una llicència expressa d'ús. Consulta al teu Ajuntament i demana ajuda per fer els passos necessaris."])},
  "docselectview municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un municipi."])},
  "docselectview province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccioneu una província."])},
  "Grant Access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atorgar accés"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
  "docloginview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segueix aquestes instruccions per accedir a CatAtom2Osm online:"])},
  "docloginview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Feu clic al botó ", _interpolate(_list(0)), " de la barra de navegació."])},
  "docloginview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sessió a OpenStreetMap usant el teu compte o un dels mètodes alternatius."])},
  "docloginview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Autoritza l'accés a CatAtom2Osm fent clic a ", _interpolate(_list(0)), "."])},
  "docloginview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El compte dedicat d'importació és necessari per contribuir a projectes publicats. \nPer crear-los pots fer servir el teu compte normal si ho desitges."])},
  "docprocessview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcions de procés."])},
  "docprocessview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informació."])},
  "docprocessview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durant l'execució del procés de conversió s'actualitza el tauler de registre amb informació de l'execució del programa."])},
  "docprocessview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En acabar, es mostra un informe amb dades sobre el resultat de l'execució."])},
  "docprocessview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un cop seleccionada la zona a processar has de decidir si vols importar alhora edificis i adreces postals o processar independentment edificis o adreces. \nL'opció més completa és la primera, encara que alguns usuaris decideixen processar només adreces si el mapa ja conté molts edificis, o processar només els edificis per reduir la càrrega de treball."])},
  "docprocessview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El selector d'idioma es refereix a l'idioma emprat en convertir les abreviatures de tipus de via emprades a Cadastre (per exemple, CL = Carrer/Carrer/Rúa). \nS'ajusta en funció de la província, per la qual cosa en principi no cal canviar-ho."])},
  "docprocessview content4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcions avançades."])},
  "docprocessview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dins l'apartat opcions avançades es troben paràmetres per modificar la manera com el programa agrupa les parcel·les amb edificis o adreces en fitxers de tasques per importar. \nEs recomana fer servir els valors per defecte."])},
  "docprocessview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El programa intentarà que els fitxers de tasques tinguin com a màxim aquest nombre de parts dedifici."])},
  "docprocessview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es fusionaran les parcel·les més properes que aquesta distància si no se supera el nombre de parts."])},
  "docprocessview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicieu el procés."])},
  "docprocessview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quan estiguis llest fes clic al botó"])},
  "docreviewview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Abans de poder publicar un projecte d'importació hi ha dues revisions que cal fer manualment. \nLa primera, és la de la guia de carrers. \nSi no heu seleccionat ", _interpolate(_list(0)), ", podeu passar a ", _interpolate(_list(1))])},
  "docreviewview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les files dels carrers revisats es mostren amb un color diferenciat per a cada usuari que n'ha fet la revisió. \nTambé podeu consultar-lo en posar el ratolí a sobre del requadre d'edició del nom del carrer. \nDiferents usuaris poden col·laborar per completar la revisió."])},
  "docreviewview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correcció"])},
  "docreviewview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el carrer no té nom a OSM, o si el té però no és igual a la conversió proposada, cerca el nom correcte usant revisió sobre el terreny o les diferents fonts disponibles (Cartociutat, les fotos de façana, Mapillary, KartaView)."])},
  "docreviewview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quan tinguis el nom correcte edita la conversió a la taula i/o el nom de la via a OSM segons sigui necessari. \nPer accedir a editar el mapa, a la capçalera del mapa hi ha un enllaç al web d'OSM."])},
  "docreviewview content14": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Les edicions al mapa resultat d'aquesta fase s'han de fer amb el compte d'usuari habitual de l'OSM, no amb els comptes dedicats d'importació. \nConvé etiquetar-les amb ", _interpolate(_list(0)), " i el nom del municipi al comentari del conjunt de canvis."])},
  "docreviewview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si un carrer es mostra amb diferents colors al llarg del traçat, és perquè està format per diferents vies en OSM i el nom no coincideix. \nCal editar el mapa, veure quin és el correcte i posar-lo a tots els trams. \nEn aquest exemple, al nom en un tram li falta una titlla."])},
  "docreviewview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quan el programa no troba una via a OSM amb què aparellar els noms, aplicarà correccions al nom a Cadastre per suggerir-lo com a conversió. \nVigila la conversió de les abreviatures del tipus de via. \nN'hi ha algunes que tenen diversos valors possibles que es mostren separats per una barra. \nSón els casos d'AL (Aldea/Alameda), CJ (Calleja/Callejón) o CR (Carretera/Carrera), per exemple. \nEn cas de fer-los servir, revisa que només quedi el tipus de via correcte o cap, segons el cas."])},
  "docreviewview content17": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Algunes direccions de Cadastre poden referir-se a llocs en comptes de vies, per exemple a barris, urbanitzacions, caserius. \nComproveu si el nom està mapejat a OSM o es pot afegir. \nTingueu en compte que el programa assigna les adreces a l'etiqueta ", _interpolate(_list(0)), " en lloc de ", _interpolate(_list(1)), " quan el nom comença per referències de lloc. \nPer a una llista completa consulteu l'annex."])},
  "docreviewview content18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "docreviewview content19": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Alguns noms de via de Cadastre poden no ser adequats per importar a OSM. \nPer exemple podem trobar referències administrativa com ara ' POMA A SECTOR 4' que no es correspon amb noms de via reals. \nSi decidiu que no s'importin les adreces per a un nom de via, deixeu la conversió en blanc mitjançant el botó ", _interpolate(_list(0))])},
  "docreviewview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversió de vials"])},
  "docreviewview content20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desfer"])},
  "docreviewview content21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per restaurar la conversió al valor original useu el botó"])},
  "docreviewview content22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocessar"])},
  "docreviewview content23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quan estigui completa la revisió de la guia de carrers, l'usuari que va iniciar el procés pot continuar al següent pas mitjançant el botó"])},
  "docreviewview content24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annex: Sigles de tipus de via"])},
  "docreviewview content25": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["*) S'aplica a ", _interpolate(_list(0)), " en lloc de ", _interpolate(_list(1)), "."])},
  "docreviewview content26": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["**) S'aplica a ", _interpolate(_list(0)), " i s'elimina el prefix \"Lloc\" a la conversió"])},
  "docreviewview content27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["***) S'eliminen les adreces d'aquests tipus."])},
  "docreviewview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cal importar els noms de carrers tal com vénen de Cadastre. \nIncompleixen les regles d'OSM (estan en majúscules, sense titlles i amb altres problemes). \nCatAtom2Osm ajuda a corregir-los intentant aparellar automàticament el nom de cada via a Cadastre amb el nom a OSM més semblant i proper. \nSi no trobeu una coincidència realitza una transformació preliminar com a suggeriment de quin hauria de ser el nom correcte. \nEn tot cas, cal revisar el procés manualment."])},
  "docreviewview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La revisió es fa mitjançant aquesta taula. \nA la columna de l'esquerra es mostra el ", _interpolate(_list(0)), " del vial, a la de la dreta la ", _interpolate(_list(1)), " proposada."])},
  "docreviewview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En fer clic a una fila apareix un mapa que mostra els portals de Cadastre existents a aquest carrer i els vials existents a OSM. \nFes clic als portals per accedir a les foto de façana de Cadastre. \nEn col·locar el ratolí sobre un vial es mostra el nom a OSM."])},
  "docreviewview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pots fer servir el selector de capes per alternar entre el mapa d'OSM i el d'IGN-Base que mostra la guia de carrers de Cartociudad, o per desactivar les capes de Portals o Vials si no et deixen veure bé el mapa."])},
  "docreviewview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validació"])},
  "docreviewview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguint els portals de Cadastre, cerca amb quin vial d'OSM es corresponen."])},
  "docreviewview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el carrer té nom a OSM, està correctament escrit, és igual a la conversió proposada i es correspon amb el nom que figura a Cadastre, pots validar la conversió usant el botó"])},
  "docfixmeview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En aquesta fase, ja està completada la conversió de dades. \nCada unitat d'importació (o tasques) s'ha creat. \nAbans de poder publicar el projecte d'importació, però, cal revisar els problemes que el programa ha detectat i marcat als edificis mitjançant etiquetes ", _interpolate(_list(0)), "."])},
  "docfixmeview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edifici amb àrea massa petita. \nSi no es correspon a un edifici real, elimina-ho."])},
  "docfixmeview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquesta part és més gran que el seu edifici"])},
  "docfixmeview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Significa que una part d'un edifici és fora del seu contorn. \nResol-ho ampliant el contorn de l'edifici o retallant la part segons sigui oportú."])},
  "docfixmeview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validació GEOS"])},
  "docfixmeview content14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La geometria no ha superat les proves de validació de la llibreria GEOS. \nEn aquest exemple, una part interior d'un multipolígon està tocant la vora exterior. \nEs pot resoldre eliminant la relació multipolígon i col·locant les etiquetes dalçada a cada part per separat."])},
  "docfixmeview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'edifici d'OSM amb id %s no és vàlid"])},
  "docfixmeview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'ha trobat un edifici amb geometria no vàlida a OSM. \nEn aquest cas el problema no és a les dades de Cadastre sinó a les d'OSM. \nComproveu que l'edifici de Cadastre se superposi al d'OSM i que serà reemplaçat (combinació) quan es faci la importació. \nEn aquest cas no cal cap acció a part d'eliminar l'etiqueta 'fixme'. \nSi l'edifici no serà reemplaçat amb les dades de Cadastre en importar, cal corregir-ho a OSM."])},
  "docfixmeview content17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desfer"])},
  "docfixmeview content18": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si us equivoqueu, sempre podeu restaurar el fitxer de tasca original usant el botó ", _interpolate(_list(0))])},
  "docfixmeview content19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocessar"])},
  "docfixmeview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La revisió es fa mitjançant aquesta llista. \nCada fila conté l'enllaç al fitxer d'una tasca amb problemes i el nombre de fixmes que conté."])},
  "docfixmeview content20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quan estigui completa la revisió de tots els fitxers, l'usuari que va iniciar el procés pot continuar al següent pas mitjançant el botó"])},
  "docfixmeview content21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annex: nomenclatura de subparcel·les"])},
  "docfixmeview content22": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Segons ", _interpolate(_list(0))])},
  "docfixmeview content3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Descarrega ", _interpolate(_list(0)), " el fitxer al teu ordinador i obre'l amb JOSM. \nFes una cerca del text 'fixme' per localitzar els elements que tinguin aquesta etiqueta. \nLlegeix el missatge del problema detectat i busca'n la descripció en aquesta pàgina. \nElimina l'etiqueta fixme i si cal corregeix el problema indicat. \nPer resoldre el problema consulteu la imatge aèria de fons, les fotos de façana i altres fonts aprovades d'imatges com Mapillary o KartaView."])},
  "docfixmeview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["No pugeu els fitxers de tasques a OSM. \nQuan estiguin corregits els problemes i no quedin etiquetes fixme guarda'l al teu ordinador i puja-ho a CatAtom2OSM. \nPodeu arrossegar els fitxers al panell o utilitzar els botons ", _interpolate(_list(0)), "."])},
  "docfixmeview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les files dels fitxers revisats es mostren amb un color diferenciat per a cada usuari que ha fet la revisió. \nTambé podeu consultar-lo en posar el ratolí sobre l'enllaç de descàrrega. \nDiferents usuaris poden col·laborar per completar la revisió."])},
  "docfixmeview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els fixmes estan pensats per corregir-los abans publicar el projecte. \nSe n'ha de reduir el nombre a 0 per poder-ho fer. \nAquesta és la llista de possibles problemes a solucionar:"])},
  "docfixmeview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Àrea massa gran"])},
  "docfixmeview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edifici amb àrea massa gran. \nSi no es correspon a un edifici real, elimina-ho."])},
  "docfixmeview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Àrea massa petita"])},
  "Select file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar fitxer"])},
  "Task Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestor de Tasques"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
  "docpublishview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Finalment, el projecte està llest per a la seva publicació al ", _interpolate(_list(0)), "."])},
  "docpublishview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccioneu l'organització OSM-ca."])},
  "docpublishview content11": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Introduïu el nom del projecte en anglès. \nA partir d'ara, completa els camps necessaris copiant-los de la plantilla mostrada al panell mitjançant els botons ", _interpolate(_list(0))])},
  "docpublishview content12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Feu clic a ", _interpolate(_list(0)), " per crear el projecte."])},
  "docpublishview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la pàgina 'EDITAR PROJECTE', 'DESCRIPCIÓ', seleccioneu l'estat 'Publicat'. \nCompleta en anglès i espanyol els camps 'Nom del projecte', 'Descripció curta' i 'Descripció'."])},
  "docpublishview content14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 'INSTRUCCIONS', completa en anglès i espanyol els camps 'Comentari al conjunt de canvis', 'Instruccions detallades' i 'Instruccions addicionals per a aquesta tasca'."])},
  "docpublishview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 'METADATS', 'Tipus de mapeig', selecciona 'Edificis'."])},
  "docpublishview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fes clic al botó 'Guardar' per finalitzar."])},
  "docpublishview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Per això necessites que et donin permís per crear projectes. \nEnvia un missatge sol·licitant-lo a qualsevol canal de comunicació enumerat a ", _interpolate(_list(0)), " indicant el teu nom d'usuari."])},
  "docpublishview content3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quan tinguis el permís assignat, fes servir el botó ", _interpolate(_list(0))])},
  "docpublishview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Descarrega el fitxer ", _interpolate(_list(0)), " ", _interpolate(_list(1)), " al teu ordinador."])},
  "docpublishview content5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A la finestra 'CREAR NOU PROJECTE, DEFINIR ÀREA' del Gestor de Tasques fes clic a ", _interpolate(_list(0)), " i selecciona el fitxer zoning.zip descarregat."])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Següent"])},
  "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important"])},
  "docpublishview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la finestra 'REVISAR', comprova que el nombre de tasques mostrat coincideix amb el que es mostra a CatAtom2OSM."])},
  "docpublishview content8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Feu clic a ", _interpolate(_list(0)), " per continuar."])},
  "docpublishview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir tasques mitjançant els polígons carregats"])},
  "docpublishview content6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ": Activeu l'opció per a ", _interpolate(_list(1)), "."])}
}
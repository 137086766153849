export default {
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dispoñible"])},
  "Addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicacións"])},
  "Advanced options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcións avanzadas"])},
  "Autorización denegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización denegada"])},
  "Building parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partes de edificios"])},
  "Buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificios"])},
  "Changeset comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar o comentario do conxunto"])},
  "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "Check fixmes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrixir erros"])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "Conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversión"])},
  "Copy to clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar no portapapeis"])},
  "DONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rematado"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "Delete filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar filtro"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrición"])},
  "Detailed instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucións detalladas"])},
  "Docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentación"])},
  "Drop here or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrastre os ficheiros corrixidos aquí ou"])},
  "Drop them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deixalos ir"])},
  "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erro"])},
  "Edited by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editado por"])},
  "English (en)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inglés (gl)"])},
  "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explora"])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "FIXME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correccións"])},
  "Feature count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de características"])},
  "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
  "Grant Access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conceder acceso"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linguaxe"])},
  "Locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado por"])},
  "Log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gravar"])},
  "Log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pechar sesión"])},
  "Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xestionar"])},
  "Name in Cadastre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome no Catastro"])},
  "Name of the project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do proxecto"])},
  "No es un archivo gzip válido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non é un ficheiro gzip válido"])},
  "No procesado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non se procesou"])},
  "No se pudo eliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non se puido eliminar"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
  "Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcións"])},
  "Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario"])},
  "Pendiente de revisar direcciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enderezos pendentes de revisión"])},
  "Pendiente de revisar problemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas pendentes de revisión"])},
  "Per task instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucións adicionais para esta tarefa"])},
  "Pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscinas"])},
  "Procesando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamento"])},
  "Proceso eliminado correctamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso eliminado correctamente"])},
  "Proceso finalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso rematado"])},
  "Proceso no encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non se atopou o proceso"])},
  "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso"])},
  "Process addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direccións de procesos"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe"])},
  "Reprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocesar"])},
  "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisión"])},
  "Review done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisións completadas"])},
  "Review street names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta o mapa das rúas"])},
  "Select file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione o ficheiro"])},
  "Select only downloaded files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione só os ficheiros descargados"])},
  "Select reviewed file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione o ficheiro corrixido"])},
  "Select the municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona o concello..."])},
  "Select the province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione a provincia..."])},
  "Select the subarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione a división..."])},
  "Servicios ATOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizos ATOM"])},
  "Share project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir proxecto"])},
  "Short description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breve descrición"])},
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rexístrate"])},
  "Sorry, no matching option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentímolo, non se atopou a opción"])},
  "Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orixe"])},
  "Source date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de publicación"])},
  "Spanish (es)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["español (en)"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "Sólo archivos de tareas existentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Só os ficheiros de tarefas existentes"])},
  "Task Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xestor de tarefas"])},
  "Task manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xestor de tarefas"])},
  "Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarefas"])},
  "Terminó con error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rematou cun erro"])},
  "The process is locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O proceso está bloqueado por"])},
  "Undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desfacer"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
  "Validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
  "You can also": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamén podes"])},
  "add_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear proxecto"])},
  "admin boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisións administrativas"])},
  "admin-boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["límites administrativos"])},
  "between_val": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["O valor debe estar entre ", _interpolate(_list(0)), " e ", _interpolate(_list(1)), "."])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
  "create_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " inicia o proceso ", _interpolate(_named("room"))])},
  "delete_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " mata o proceso ", _interpolate(_named("room"))])},
  "delete_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar o proceso (acción coidadosa e irreversible)."])},
  "docfixmeview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nesta fase, a conversión de datos xa está rematada. \nCreáronse cada unidade (ou tarefas) de importación. \nNon obstante, antes de poder publicar o proxecto de importación, debe revisar os problemas que o programa detectou e marcou nos edificios mediante as etiquetas ", _interpolate(_list(0)), "."])},
  "docfixmeview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio cunha superficie demasiado pequena. \nSe non corresponde a un edificio real, elimínao."])},
  "docfixmeview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta parte é máis grande que o teu edificio"])},
  "docfixmeview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Significa que unha parte dun edificio está fóra do seu límite. \nResolvídeo ampliando o contorno do edificio ou recortando a parte segundo corresponda."])},
  "docfixmeview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validación GEOS"])},
  "docfixmeview content14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A xeometría non superou as probas de validación da biblioteca GEOS. \nNeste exemplo, unha parte interior dun multipolígono está tocando o bordo exterior do multipolígono. \nPódese resolver eliminando a relación multipolígono e colocando as etiquetas de altura en cada parte por separado."])},
  "docfixmeview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O edificio OSM co ID %s non é válido"])},
  "docfixmeview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atopouse un edificio con xeometría non válida en OSM. \nNeste caso o problema non está nos datos do Catastro senón nos datos OSM. \nComprobar que o edificio do Catastro se solapa co edificio OSM e que será substituído (combinación) cando se faga a importación. \nNese caso, non é necesaria ningunha acción que non sexa eliminar a etiqueta \"fixme\". \nSe o edificio non vai ser substituído polos datos do Catastro ao importar, este debe ser corrixido en OSM."])},
  "docfixmeview content17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desfacer"])},
  "docfixmeview content18": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Se cometes un erro, sempre podes restaurar o ficheiro de tarefa orixinal usando o botón ", _interpolate(_list(0))])},
  "docfixmeview content19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocesar"])},
  "docfixmeview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A revisión realízase mediante esta lista. \nCada fila contén a ligazón ao ficheiro dunha tarefa problema e o número de fixmes que contén."])},
  "docfixmeview content20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cando se complete a revisión de todos os ficheiros, o usuario que iniciou o proceso pode continuar co seguinte paso usando o botón"])},
  "docfixmeview content21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexo: nomenclatura de subparcelas"])},
  "docfixmeview content22": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Segundo ", _interpolate(_list(0))])},
  "docfixmeview content3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Descarga ", _interpolate(_list(0)), " o ficheiro no teu ordenador e ábreo con JOSM. \nFai unha busca polo texto \"fixme\" para localizar os elementos que teñan esa etiqueta. \nLe a mensaxe do problema detectado e busca a súa descrición nesta páxina. \nElimina a etiqueta fixme e, se é necesario, corrixe o problema indicado. \nPara resolver o problema, consulta a imaxe aérea de fondo, as fotos da fachada e outras fontes de imaxe aprobadas como Mapillary ou KartaView."])},
  "docfixmeview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Non cargue ficheiros de tarefas a OSM. \nCando se corrixan os problemas e non queden etiquetas fixme, gárdao no seu ordenador e cárgueo en CatAtom2OSM. \nPodes arrastrar os ficheiros ao panel ou usar os botóns ", _interpolate(_list(0)), "."])},
  "docfixmeview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As filas dos ficheiros revisados ​​móstranse cunha cor diferente para cada usuario que fixo a revisión. \nTamén podes comprobalo pasando o rato sobre a ligazón de descarga. \nDistintos usuarios poden colaborar para completar a revisión."])},
  "docfixmeview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os 'fixmes' están deseñados para corrixilos antes de publicar o proxecto. \nO seu número debe reducirse a 0 para poder facelo. \nEsta é a lista de posibles problemas a resolver:"])},
  "docfixmeview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área demasiado grande"])},
  "docfixmeview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio cunha superficie demasiado grande. \nSe non corresponde a un edificio real, elimínao."])},
  "docfixmeview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área moi pequena"])},
  "docloginview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siga estas instrucións para acceder a CatAtom2Osm en liña:"])},
  "docloginview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fai clic no botón ", _interpolate(_list(0)), " da barra de navegación."])},
  "docloginview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión en OpenStreetMap usando a túa conta ou un dos métodos alternativos."])},
  "docloginview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Autorice o acceso a CatAtom2Osm facendo clic en ", _interpolate(_list(0)), "."])},
  "docloginview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A conta de importación dedicada é necesaria para contribuír aos proxectos publicados. \nPara crealos pode usar a súa conta normal se o desexa."])},
  "docprocessview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcións de proceso."])},
  "docprocessview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información."])},
  "docprocessview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante a execución do proceso de conversión, o panel de rexistro actualízase con información sobre a execución do programa."])},
  "docprocessview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao rematar, móstrase un informe con datos sobre o resultado da execución."])},
  "docprocessview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unha vez seleccionada a área a procesar, debe decidir se quere importar edificios e enderezos postais ao mesmo tempo ou procesar edificios ou enderezos de forma independente. \nA opción máis completa é a primeira, aínda que algúns usuarios deciden procesar só enderezos se o mapa xa contén moitos edificios, ou procesar só edificios para reducir a carga de traballo."])},
  "docprocessview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O selector de idiomas refírese ao idioma empregado ao converter as abreviaturas do tipo de estrada utilizadas en Catastro (por exemplo, CL = Calle/Carrer/Rúa). \nAxústase segundo a provincia, polo que en principio non é necesario cambialo."])},
  "docprocessview content4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcións avanzadas."])},
  "docprocessview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dentro da sección de opcións avanzadas hai parámetros para modificar a forma en que o programa agrupa parcelas con edificios ou enderezos en ficheiros de tarefas a importar. \nRecoméndase utilizar os valores predeterminados."])},
  "docprocessview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O programa tentará garantir que os ficheiros de tarefas teñan un máximo deste número de pezas de construción."])},
  "docprocessview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As parcelas máis próximas a esta distancia fusionaranse se non se supera o número de pezas."])},
  "docprocessview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia o proceso."])},
  "docprocessview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cando esteas listo fai clic no botón"])},
  "docpublishview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Finalmente, o proxecto está listo para a súa publicación en ", _interpolate(_list(0)), "."])},
  "docpublishview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione a organización OSM-es."])},
  "docpublishview content11": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Introduza o nome do proxecto en inglés. \nA partir de agora, complete os campos necesarios copiándoos do modelo que se mostra no panel mediante os botóns ", _interpolate(_list(0))])},
  "docpublishview content12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fai clic en ", _interpolate(_list(0)), " para crear o proxecto."])},
  "docpublishview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na páxina \"EDITAR PROXECTO\", \"DESCRICIÓN\", seleccione o estado \"Publicado\". \nCompleta os campos \"Nome do proxecto\", \"Descrición breve\" e \"Descrición\" en inglés e español."])},
  "docpublishview content14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En \"INSTRUCIÓNS\", completa os campos \"Cambiar o comentario do conxunto\", \"Instrucións detalladas\" e \"Instrucións adicionais para esta tarefa\" en inglés e español."])},
  "docpublishview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En \"METADATOS\", \"Tipos de cartografía\", seleccione \"Edificios\"."])},
  "docpublishview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai clic no botón \"Gardar\" para rematar."])},
  "docpublishview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Para iso, debes ter permiso para crear proxectos. \nEnvía unha mensaxe solicitándoo a calquera canle de comunicación indicado en ", _interpolate(_list(0)), " indicando o teu nome de usuario."])},
  "docpublishview content3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cando teñas o permiso asignado, utiliza o botón ", _interpolate(_list(0))])},
  "docpublishview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Descarga o ficheiro ", _interpolate(_list(0)), " ", _interpolate(_list(1)), " no teu ordenador."])},
  "docpublishview content5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Na xanela \"CREAR NOVO PROXECTO, DEFINE AREA\" do Xestor de tarefas, faga clic en ", _interpolate(_list(0)), " e seleccione o ficheiro zoning.zip descargado."])},
  "docpublishview content6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ": activa a opción para ", _interpolate(_list(1)), "."])},
  "docpublishview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define tarefas utilizando polígonos cargados"])},
  "docpublishview content8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fai clic en ", _interpolate(_list(0)), " para continuar."])},
  "docpublishview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na xanela 'REVISIÓN', comprobe que o número de tarefas mostradas coincide co mostrado en CatAtom2OSM."])},
  "docreviewview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Antes de poder publicar un proxecto de importación, hai dúas comprobacións que se deben facer manualmente. \nA primeira é a dos vagabundos. \nSe non seleccionou ", _interpolate(_list(0)), ", pode pasar a ", _interpolate(_list(1))])},
  "docreviewview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As filas das rúas revisadas móstranse cunha cor diferente para cada usuario que fixo a revisión. \nTamén podes comprobalo pasando o rato sobre a caixa de edición do nome da rúa. \nDistintos usuarios poden colaborar para completar a revisión."])},
  "docreviewview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrección"])},
  "docreviewview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a rúa non ten un nome en OSM, ou se o ten pero non é o mesmo que a conversión proposta, busque o nome correcto mediante a revisión de campo ou as diferentes fontes dispoñibles (Cartociudad, fotos de fachada, Mapillary, KartaView) ."])},
  "docreviewview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cando teña o nome correcto, edite a conversión na táboa e/ou o nome da ruta en OSM segundo sexa necesario. \nPara acceder e editar o mapa, na cabeceira do mapa hai unha ligazón á páxina web de OSM."])},
  "docreviewview content14": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["As edicións do mapa resultantes desta fase deben realizarse coa conta de usuario normal de OSM, non con contas de importación dedicadas. \nÉ recomendable etiquetalos con ", _interpolate(_list(0)), " e o nome do concello no comentario do conxunto de cambios."])},
  "docreviewview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se unha rúa se mostra con cores diferentes ao longo do seu trazado, é porque está formada por diferentes estradas en OSM e o nome non coincide. \nHai que editar o mapa, ver cal é o correcto e poñelo en todos os apartados. \nNeste exemplo, ao nome dunha sección falta un acento."])},
  "docreviewview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cando o programa non atopa un camiño en OSM para coincidir cos nomes, aplicará correccións ao nome en Catastro para suxerilo como conversión. \nSupervisar a conversión das abreviaturas do tipo de estrada. \nHai algúns que teñen varios valores posibles que se mostran separados por unha barra. \nSon os casos de AL (Vila/Alameda), CJ (Calleja/Allejón) ou CR (estrada/Carrera), por exemplo. \nSe as usas, comproba que só queda o tipo de pista correcto ou ningunha, segundo o caso."])},
  "docreviewview content17": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Algúns enderezos do Catastro poden referirse a lugares en lugar de estradas, por exemplo, a barrios, urbanizacións, aldeas. \nComproba se o nome está mapeado en OSM ou se pode engadir. \nTeña en conta que o programa asigna enderezos á etiqueta ", _interpolate(_list(0)), " en lugar de ", _interpolate(_list(1)), " cando o nome comeza con referencias de lugares. \nPara unha lista completa ver o anexo."])},
  "docreviewview content18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "docreviewview content19": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["É posible que algúns nomes de estradas do Catastro non sexan axeitados para importar a OSM. \nPor exemplo, podemos atopar referencias administrativas como 'MANZANA A SECTOR 4' que non se corresponden con nomes reais de estradas. \nSe decide non importar enderezos para o nome dunha estrada, deixe a conversión en branco usando o botón ", _interpolate(_list(0))])},
  "docreviewview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversión de vial"])},
  "docreviewview content20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desfacer"])},
  "docreviewview content21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para restaurar a conversión ao seu valor orixinal, use o botón"])},
  "docreviewview content22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocesar"])},
  "docreviewview content23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cando se complete a revisión do mapa de rúas, o usuario que iniciou o proceso pode continuar co seguinte paso usando o botón"])},
  "docreviewview content24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexo: Siglas de tipo de estrada"])},
  "docreviewview content25": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["*) Aplícase a ", _interpolate(_list(0)), " en lugar de a ", _interpolate(_list(1)), "."])},
  "docreviewview content26": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["**) Aplícase a ", _interpolate(_list(0)), " e elimina o prefixo \"Lugar\" na conversión"])},
  "docreviewview content27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["***) Elimínanse os enderezos destes tipos."])},
  "docreviewview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non se deben importar os nomes das rúas xa que proceden de Catastro. \nIncumpren as normas da OSM (están en maiúsculas, sen acentos e con outros problemas). \nCatAtom2Osm axuda a corrixilos facendo coincidir automaticamente o nome de cada estrada en Catastro co nome máis semellante e máis próximo en OSM. \nSe non atopa coincidencia, realiza unha transformación preliminar como suxestión de cal debería ser o nome correcto. \nEn todo caso, é necesario revisar o proceso manualmente."])},
  "docreviewview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A revisión realízase mediante esta táboa. \nNa columna da esquerda móstrase o ", _interpolate(_list(0)), " do frasco, na columna da dereita o ", _interpolate(_list(1)), " proposto."])},
  "docreviewview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cando se fai clic nunha fila, aparece un mapa que mostra os portais do Catastro existentes nesa rúa e as vías existentes en OSM. \nPreme nos portais para acceder ás fotos da fachada do Catastro. \nColocar o rato sobre un frasco mostra o nome en OSM."])},
  "docreviewview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podes usar o selector de capas para cambiar entre o mapa OSM e o mapa IGN-Base que mostra o mapa de rúas de Cartociudad, ou para desactivar as capas de Portais ou Estradas se non che permiten ver ben o mapa."])},
  "docreviewview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validación"])},
  "docreviewview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguindo os portais do Catastro, busca a que vial OSM corresponden."])},
  "docreviewview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se a rúa ten un nome en OSM, está escrito correctamente, é o mesmo que a conversión proposta e corresponde ao nome que aparece no Catastro, podes validar a conversión mediante o botón"])},
  "docselectview admin level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcionalmente, seleccione unha división administrativa."])},
  "docselectview check size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comproba o tamaño do proxecto."])},
  "docselectview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siga estas instrucións para seleccionar a área a procesar:"])},
  "docselectview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despois de seleccionar un concello, no panel informativo terás datos que che permitirán facerte unha idea do tamaño que terá o proxecto. \nCantos máis edificios ou enderezos, maior será o traballo. \nÉ importante que un proxecto non sexa demasiado grande, especialmente se é a primeira vez que creas un. \nCanto maior sexa, máis tempo levará a revisión previa á publicación e completar a importación unha vez publicada."])},
  "docselectview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como norma xeral, podemos dicir que un proxecto é demasiado grande se o número de pezas do edificio é superior a 10.000 O programa avisará se supera esta cifra. \nSe o proxecto é grande e non queres comezar con outro concello máis pequeno, tenta seleccionar unha división administrativa."])},
  "docselectview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Os municipios adoitan dividirse en entidades administrativas máis pequenas. \nEn OSM, os concellos teñen a etiqueta ", _interpolate(_list(0)), ". \nAbaixo, con ", _interpolate(_list(1)), ", están os distritos (o nome pode cambiar segundo as peculiaridades de cada territorio). \nDentro de cada distrito haberá barrios (ou o equivalente na túa zona), con ", _interpolate(_list(2)), "."])},
  "docselectview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se estes límites administrativos están definidos en OSM, aparecerán no selector de división administrativa debaixo do selector de municipios. \nSeleccione un barrio que teña o tamaño adecuado para procesar."])},
  "docselectview content6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Se os ", _interpolate(_list(0)), " non están definidos en OSM, é recomendable crear os que necesites. \nSe vas utilizar datos de terceiros para trazar estes límites e non están publicados cunha licenza compatible, será necesario solicitar unha licenza expresa de uso. \nConsulte o seu Concello e solicite axuda para tomar as xestións necesarias."])},
  "docselectview municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un concello."])},
  "docselectview province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione unha provincia."])},
  "docview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta ferramenta en liña utilízase para converter datos de edificios e enderezos do Catastro ao formato OSM. \nA conversión realízase por áreas, xeralmente un concello, e o resultado é un proxecto de importación dispoñible para que diferentes usuarios poidan realizar a importación de forma colaborativa."])},
  "docview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["É interesante que antes de crear o teu primeiro proxecto saibas como é o proceso de participación nos que xa están publicados. \nAtrévete a colaborar con eles seguindo a ", _interpolate(_list(0)), "."])},
  "docview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para crear un proxecto para unha zona inédita é necesario seguir estes pasos:"])},
  "docview fixme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrixir erros"])},
  "docview forum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Foro ", _interpolate(_list(0))])},
  "docview login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gravar"])},
  "docview mail list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de correo Talk"])},
  "docview need help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesitas axuda?"])},
  "docview need help end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alí atoparás outros usuarios dispostos a responder ás túas dúbidas ou colaborar cando sexa necesario."])},
  "docview need help explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non dubides en contactar connosco a través de calquera destes medios:"])},
  "docview process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso"])},
  "docview publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicación"])},
  "docview review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta o mapa das rúas"])},
  "docview select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione"])},
  "docview telegram matrix": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "/", _interpolate(_list(1)), " canle"])},
  "docview title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xestión de proxectos"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feito"])},
  "done_msg1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Crea un novo proxecto en ", _interpolate(_list(0)), " usando o ficheiro ", _interpolate(_list(1)), "."])},
  "done_msg2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Completa os campos necesarios copiándoos (", _interpolate(_list(0)), ") do modelo que aparece no panel."])},
  "done_msg3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Consulta o ", _interpolate(_list(0)), "."])},
  "export_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga os resultados do traballo como copia de seguridade."])},
  "finish_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Proceso de ", _interpolate(_list(0)), " completado"])},
  "fixme_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Descarga (", _interpolate(_named("descarga")), ") os ficheiros que aparecen no panel. \nEdítaos con JOSM seguindo estes ", _interpolate(_named("link")), ". \nGarda os resultados e carga os ficheiros corrixidos (", _interpolate(_named("cargar")), "). \nComprobe os edificios con etiquetas \"fixme\" e elimínaos. \nNon tes que cargar os datos a OSM, pero aquí."])},
  "fixmes left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Falta ", _interpolate(_named("n")), " fixme."]), _normalize(["Faltan ", _interpolate(_named("n")), " fixme."])])},
  "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instrucións"])},
  "hero content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As importacións e edicións automatizadas deben ser realizadas por persoas con experiencia e coñecementos suficientes sobre como a comunidade OpenStreetMap crea mapas e cunha planificación e consulta coidadosa coa comunidade local relevante."])},
  "hero content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Consulta ", _interpolate(_list(0)), ". \nAs importacións ou edicións automatizadas que non seguen estas directrices pódense reverter."])},
  "hero subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ferramenta en liña para converter edificios e enderezos de ficheiros de Catastro ", _interpolate(_list(0)), " a ", _interpolate(_list(1)), "."])},
  "hero title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importación do Catastro Español a OpenStreetMap"])},
  "highways left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Faltan ", _interpolate(_named("n")), " camiños."]), _normalize(["Faltan ", _interpolate(_named("n")), " camiños."])])},
  "import guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guía de importación"])},
  "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante"])},
  "joined": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " uniuse a ", _interpolate(_named("room"))])},
  "leave": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " deixou ", _interpolate(_named("sala"))])},
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galego"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguindo"])},
  "parcel_dist_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia en metros para engadir parcelas para tarefas."])},
  "parcel_parts_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pezas de construción para comezar a engadir parcelas para tarefas."])},
  "parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pezas"])},
  "process result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultado do proceso"])},
  "project-size-ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["O proxecto é moi grande. \nRecoméndase seleccionar un distrito ou barrio. \nSe non están definidos en OSM, crea relacións de ", _interpolate(_list(0)), " co nivel 9 ou 10."])},
  "project-size-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tamaño do proxecto é adecuado para procesar."])},
  "restart_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " reinicia o proceso ", _interpolate(_named("room"))])},
  "review_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Comproba os nomes das estradas/lugares que aparecen no panel seguindo estes ", _interpolate(_list(0)), ". \nNa columna da esquerda móstrase o nome da estrada/lugar en Catastro, na dereita a proposta de conversión que se utilizará no proceso de reprocesamento. \nEdita os que non son correctos ou valida (", _interpolate(_list(1)), ") os que o son. \nDéixao en branco (", _interpolate(_list(2)), ") para excluír todos os enderezos nese camiño. \nCando remates, continúa co seguinte paso."])},
  "reviewed_files": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisáronse ", _interpolate(_list(0)), " de ", _interpolate(_list(1)), " ficheiros."])},
  "reviewed_hgw": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Marcadas ", _interpolate(_list(0)), " de ", _interpolate(_list(1)), " rúas."])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seleccionalos"])},
  "select_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Selecciona unha provincia e o concello para tramitar. \nSe hai ", _interpolate(_list(0)), " do municipio (admin_level = 9 - distritos ou admin_level = 10 - barrios), tamén podes seleccionar un."])},
  "view_in_osm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver en OpenStreetMap"])},
  "welldone1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben feito! \nConfirma para continuar."])},
  "welldone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben feito! \nReprocesar para continuar."])},
  "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correndo"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rúa"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicación"])},
  "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proxecto"])},
  "Process buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificios de proceso"])}
}
export default {
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "Docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docs"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "Log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guide"])},
  "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "Advanced options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced options"])},
  "Process buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process buildings"])},
  "Process addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process addresses"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "admin-boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["administrative boundaries"])},
  "project-size-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project size is suitable for processing."])},
  "project-size-ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The project is very big. It is recommended to select a district or neighborhood. If they are not defined in OSM, create relations of ", _interpolate(_list(0)), " with level 9 or 10."])},
  "Source date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source date"])},
  "Feature count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feature count"])},
  "Pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pools"])},
  "Building parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building parts"])},
  "Review street names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review street names"])},
  "review_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Check out the highway/place names displayed on the panel following this ", _interpolate(_list(0)), ". The left column shows the name of the highway/place in Cadastre, the right the proposed conversion to be used when reprocessing. Edit those that are not correct or validate (", _interpolate(_list(1)), ") those that are. Leave it blank (", _interpolate(_list(2)), ") to exclude all addresses on that highway. When you're done, continue to the next step."])},
  "Reprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocess"])},
  "Check fixmes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check fixmes"])},
  "Locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked by"])},
  "Edited by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited by"])},
  "fixmes left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " fixme left."]), _normalize([_interpolate(_named("n")), " fixmes left."])])},
  "highways left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " highway left."]), _normalize([_interpolate(_named("n")), " highways left."])])},
  "reviewed_hgw": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Reviewed ", _interpolate(_list(0)), " of ", _interpolate(_list(1)), " streets."])},
  "reviewed_files": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Reviewed ", _interpolate(_list(0)), " of ", _interpolate(_list(1)), " files."])},
  "fixme_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Download ", _interpolate(_named("upload")), " the files displayed in the panel. Edit them with JOSM following this ", _interpolate(_named("link")), ". Save the results and upload the corrected files ", _interpolate(_named("upload")), ". Check the buildings that have \"fixme\" tags and remove them. You don't have to upload the data to OSM, but here."])},
  "welldone1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well done! Confirm to continue."])},
  "welldone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well done! Reprocess to continue."])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "finish_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " process finished"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
  "Task manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task manager"])},
  "done_msg1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Create a new proyect in the ", _interpolate(_list(0)), " using the file ", _interpolate(_list(1)), "."])},
  "done_msg2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fill in the necessary fields by copying (", _interpolate(_list(0)), ") from the template displayed on the panel."])},
  "done_msg3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["View the ", _interpolate(_list(0)), "."])},
  "process result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["process result"])},
  "You can also": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also"])},
  "Buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buildings"])},
  "Addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
  "Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
  "export_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the process results for backup."])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "delete_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the process (caution, can't be undone)."])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "Copy to clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to clipboard"])},
  "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
  "Spanish (es)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish (es)"])},
  "English (en)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (en)"])},
  "Name of the project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the project"])},
  "Short description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short description"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "Changeset comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changeset comment"])},
  "Detailed instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed instructions"])},
  "Per task instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per task instructions"])},
  "Select only downloaded files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select only downloaded files"])},
  "Drop them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop them"])},
  "Drop here or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop the reviewed files here or"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select them"])},
  "Select reviewed file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select reviewed file"])},
  "Sorry, no matching option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, no matching option"])},
  "Select the province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the province…"])},
  "Select the municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the municipality…"])},
  "Select the subarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the subarea…"])},
  "admin boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin boundaries"])},
  "select_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Select the province and municipality to process. If there exists lower ", _interpolate(_list(0)), " (admin_level = 9 - Distrito, admin_level =10 – Barrio), you can also select one."])},
  "Log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log"])},
  "The process is locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process is locked by"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "Delete filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete filter"])},
  "Name in Cadastre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name in Cadastre"])},
  "Conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion"])},
  "joined": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " joined ", _interpolate(_named("room"))])},
  "leave": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " leave ", _interpolate(_named("room"))])},
  "Procesando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
  "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
  "Review done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review done"])},
  "Pendiente de revisar direcciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending to review addresses"])},
  "Pendiente de revisar problemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending to review fixmes"])},
  "Proceso finalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process finished"])},
  "No procesado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not processed"])},
  "Terminó con error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished with error"])},
  "Autorización denegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denied authorization"])},
  "No es un archivo gzip válido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a valid gzip file"])},
  "Sólo archivos de tareas existentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only existing task files"])},
  "No se pudo eliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not delete"])},
  "Proceso eliminado correctamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process succesfully deleted"])},
  "Proceso no encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process not found"])},
  "create_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " started processing of ", _interpolate(_named("room"))])},
  "restart_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " restarted processing of ", _interpolate(_named("room"))])},
  "delete_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " deletes processing of ", _interpolate(_named("room"))])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "view_in_osm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View in OpenStreetMap"])},
  "Validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
  "Undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
  "parcel_parts_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of building parts to agregate parcels for tasks."])},
  "parcel_dist_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance in meters to agregate parcels for tasks."])},
  "between_val": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The value must be between ", _interpolate(_list(0)), " and ", _interpolate(_list(1)), "."])},
  "add_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add project"])},
  "Share project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share project"])},
  "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
  "Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
  "parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parts"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
  "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["running"])},
  "DONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["done"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review"])},
  "FIXME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fixmes"])},
  "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error"])},
  "hero title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importing the Spanish Cadastre into OpenStreetMap"])},
  "hero subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Online tool to convert buildings and addresses from ", _interpolate(_list(0)), " Cadastre files to ", _interpolate(_list(1)), " files."])},
  "Servicios ATOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATOM Services"])},
  "hero content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automated imports and edits should be carried out by people with experience and sufficient knowledge of how the OpenStreetMap community creates maps, and with careful planning and consultation with the relevant local community."])},
  "hero content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["See the ", _interpolate(_list(0)), ". Automated imports or edits that do not follow these guidelines may be reverted!"])},
  "import guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["import guide"])},
  "docview title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Management"])},
  "docview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This online tool is used to convert building and address data from the Cadastre to the OSM format. The conversion is done by zones, usually a municipality, and the result is an import project available so that different users can carry out the import collaboratively."])},
  "docview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["It is interesting that before creating your first project you know how the participation process is in those that are already published. Go ahead and collaborate on them by following the ", _interpolate(_list(0)), "."])},
  "docview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create a project for an unpublished zone, you need to follow these steps:"])},
  "docview login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "docview select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select zone"])},
  "docview process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "docview review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review the street map"])},
  "docview fixme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct errors"])},
  "docview publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication"])},
  "docview need help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need help?"])},
  "docview need help explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feel free to contact us through any of these means:"])},
  "docview mail list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk-es mailing list"])},
  "docview telegram matrix": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Channel ", _interpolate(_list(0)), " / ", _interpolate(_list(1))])},
  "docview forum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Forum ", _interpolate(_list(0))])},
  "docview need help end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There you will find other users willing to resolve your doubts or collaborate in whatever is necessary."])},
  "docselectview admin level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionally, select an administrative division."])},
  "docselectview check size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the size of the project."])},
  "docselectview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow these instructions to select the area to process:"])},
  "docselectview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After selecting a municipality, in the information panel you will have data that allows you to get an idea of the size the project will have. The more buildings or addresses, the greater the work. It is important that a project is not too big, especially if it is the first time you create one. The larger it is, the more time it will take for pre-publication review and to complete the import once published."])},
  "docselectview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a general rule, we can say that a project is too large if the number of building parts is greater than 10,000. The program will alert you if it exceeds this figure. If the project is large and you do not want to start with another smaller municipality, try selecting an administrative division."])},
  "docselectview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Municipalities are usually divided into smaller administrative entities. In OSM, municipalities have the label ", _interpolate(_list(0)), ". Below, with ", _interpolate(_list(1)), ", are the districts (the name may change according to the peculiarities of each territory). Within each district there will be neighborhoods (or the equivalent in your area), with ", _interpolate(_list(2)), "."])},
  "docselectview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If these administrative boundaries are defined in OSM, they will appear in the administrative division selector below the municipality selector. Select a neighborhood that is the appropriate size to process."])},
  "docselectview content6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["If the ", _interpolate(_list(0)), " are not defined in OSM, it is advisable to create the ones you need. If you are going to use third-party data to draw these limits and they are not published with a compatible license, it will be necessary to request an express license for use. Consult your City Council and ask for help to take the necessary steps."])},
  "docselectview municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a municipality."])},
  "docselectview province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a province."])},
  "Grant Access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant access"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "docloginview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow these instructions to access CatAtom2Osm online:"])},
  "docloginview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Click the ", _interpolate(_list(0)), " button in the navigation bar."])},
  "docloginview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to OpenStreetMap using your account or one of the alternative methods."])},
  "docloginview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Authorize access on CatAtom2Osm by clicking ", _interpolate(_list(0)), "."])},
  "docloginview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The dedicated import account is required to contribute to published projects. \nTo create them you can use your normal account if you wish."])},
  "docprocessview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process options."])},
  "docprocessview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information."])},
  "docprocessview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During the execution of the conversion process, the log panel is updated with information about the program execution."])},
  "docprocessview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When finished, a report is displayed with data on the result of the execution."])},
  "docprocessview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have selected the area to process, you must decide if you want to import buildings and postal addresses at the same time or process buildings or addresses independently. \nThe most complete option is the first, although some users decide to process only addresses if the map already contains many buildings, or to process only buildings to reduce the workload."])},
  "docprocessview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The language selector refers to the language used when converting the road type abbreviations used in Cadastre (for example, CL = Calle/Carrer/Rúa). \nIt is adjusted depending on the province, so in principle it is not necessary to change it."])},
  "docprocessview content4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced options."])},
  "docprocessview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within the advanced options section there are parameters to modify the way the program groups parcels with buildings or addresses in task files to import. \nIt is recommended to use the default values."])},
  "docprocessview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The program will try to ensure that the task files have a maximum of this number of building parts."])},
  "docprocessview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcels closer than this distance will be merged if the number of parts is not exceeded."])},
  "docprocessview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start the process."])},
  "docprocessview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you are ready click the button"])},
  "docreviewview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Before you can publish an import project, there are two checks that must be done manually. \nThe first is that of the stray. \nIf you have not selected ", _interpolate(_list(0)), ", you can skip to ", _interpolate(_list(1))])},
  "docreviewview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rows of the reviewed streets are shown with a different color for each user who has made the review. \nYou can also check it by hovering your mouse over the street name edit box. \nDifferent users can collaborate to complete the review."])},
  "docreviewview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction"])},
  "docreviewview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the street does not have a name in OSM, or if it has one but it is not the same as the proposed conversion, look for the correct name using field review or the different available sources (Cartociudad, facade photos, Mapillary, KartaView)."])},
  "docreviewview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you have the correct name edit the conversion in the table and/or the path name in OSM as necessary. \nTo access and edit the map, in the map header there is a link to the OSM website."])},
  "docreviewview content14": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Edits to the map resulting from this phase must be made with the regular OSM user account, not with dedicated import accounts. \nIt is advisable to label them with ", _interpolate(_list(0)), " and the name of the municipality in the comment of the set of changes."])},
  "docreviewview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a street is shown with different colors along its layout, it is because it is made up of different roads in OSM and the name does not match. \nYou have to edit the map, see which one is correct and put it in all the sections. \nIn this example, the name in a section is missing an accent."])},
  "docreviewview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the program does not find a path in OSM to match the names with, it will apply corrections to the name in Cadastre to suggest it as a conversion. \nMonitor the conversion of road type abbreviations. \nThere are some that have several possible values ​​that are shown separated by a bar. \nThese are the cases of AL (Aldea/Alameda), CJ (Calleja/Callejón) or CR (Carretera/Carrera), for example. \nIf you use them, check that only the correct type of track remains or none, as the case may be."])},
  "docreviewview content17": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Some Cadastre addresses may refer to places instead of roads, for example to neighborhoods, urbanizations, hamlets. \nChecks if the name is mapped in OSM or can be added. \nNote that the program maps addresses to the tag ", _interpolate(_list(0)), " instead of ", _interpolate(_list(1)), " when the name begins with place references. \nFor a complete list see the annex."])},
  "docreviewview content18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminate"])},
  "docreviewview content19": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Some Cadastre road names may not be suitable for import into OSM. \nFor example, we can find administrative references such as 'MANZANA A SECTOR 4' that do not correspond to real road names. \nIf you decide not to import addresses for a road name, leave the conversion blank using the ", _interpolate(_list(0)), " button"])},
  "docreviewview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vial conversion"])},
  "docreviewview content20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
  "docreviewview content21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore the conversion to its original value use the button"])},
  "docreviewview content22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocess"])},
  "docreviewview content23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the street map review is complete, the user who started the process can continue to the next step using the button"])},
  "docreviewview content24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annex: Acronyms for road type"])},
  "docreviewview content25": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["*) Applies to ", _interpolate(_list(0)), " instead of ", _interpolate(_list(1)), "."])},
  "docreviewview content26": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["**) Applies to ", _interpolate(_list(0)), " and removes the \"Place\" prefix in the conversion"])},
  "docreviewview content27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["***) The addresses of these types are removed."])},
  "docreviewview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street names should not be imported as they come from Cadastre. \nThey violate OSM rules (they are in capital letters, without accents and with other problems). \nCatAtom2Osm helps to correct them by automatically matching the name of each road in Cadastre with the most similar and closest name in OSM. \nIf it does not find a match it performs a preliminary transformation as a suggestion of what the correct name should be. \nIn any case, it is necessary to review the process manually."])},
  "docreviewview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The review is done using this table. \nIn the left column the ", _interpolate(_list(0)), " of the vial is shown, in the right column the proposed ", _interpolate(_list(1)), "."])},
  "docreviewview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you click on a row, a map appears that shows the existing Cadastre portals on that street and the existing roads in OSM. \nClick on the portals to access the Cadastro facade photos. \nPlacing the mouse over a vial displays the name in OSM."])},
  "docreviewview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use the layer selector to switch between the OSM map and the IGN-Base map that shows the Cartociudad street map, or to deactivate the Portals or Roads layers if they do not allow you to see the map well."])},
  "docreviewview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
  "docreviewview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following the Cadastre portals, look for which OSM vial they correspond to."])},
  "docreviewview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the street has a name in OSM, it is correctly written, it is the same as the proposed conversion and it corresponds to the name that appears in the Cadastre, you can validate the conversion using the button"])},
  "docfixmeview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["In this phase, the data conversion is already completed. \nEach import unit (or tasks) have been created. \nHowever, before you can publish the import project, you must review the problems that the program has detected and marked in the buildings using ", _interpolate(_list(0)), " tags."])},
  "docfixmeview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building with too small area. \nIf it doesn't correspond to a real building, delete it."])},
  "docfixmeview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This part is bigger than your building"])},
  "docfixmeview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It means that a part of a building is outside its boundary. \nSolve it by enlarging the outline of the building or cutting out the part as appropriate."])},
  "docfixmeview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEOS Validation"])},
  "docfixmeview content14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The geometry has not passed the validation tests of the GEOS library. \nIn this example, an interior part of a multipolygon is touching the exterior edge of the multipolygon. \nIt can be solved by removing the multipolygon relationship and placing the height labels on each part separately."])},
  "docfixmeview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OSM building with id %s is invalid"])},
  "docfixmeview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A building with invalid geometry has been found in OSM. \nIn this case the problem is not in the Cadastre data but in the OSM data. \nCheck that the Cadastre building overlaps the OSM building and that it will be replaced (combination) when the import is made. \nIn that case no action is necessary other than removing the 'fixme' tag. \nIf the building is not going to be replaced with the Cadastre data when importing, this must be corrected in OSM."])},
  "docfixmeview content17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
  "docfixmeview content18": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["If you make a mistake, you can always restore the original task file using the ", _interpolate(_list(0)), " button"])},
  "docfixmeview content19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocess"])},
  "docfixmeview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The review is done using this list. \nEach row contains the link to the file of a problem task and the number of fixmes it contains."])},
  "docfixmeview content20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the review of all files is complete, the user who started the process can continue to the next step using the button"])},
  "docfixmeview content21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annex: nomenclature of subplots"])},
  "docfixmeview content22": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["According to ", _interpolate(_list(0))])},
  "docfixmeview content3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Download ", _interpolate(_list(0)), " the file to your computer and open it with JOSM. \nDo a search for the text 'fixme' to locate items that have that tag. \nRead the message of the detected problem and look for its description on this page. \nRemove the fixme tag and if necessary correct the indicated problem. \nTo resolve the issue, consult the background aerial image, facade photos, and other approved image sources such as Mapillary or KartaView."])},
  "docfixmeview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Do not upload task files to OSM. \nWhen the problems are corrected and there are no fixme tags left, save it to your computer and upload it to CatAtom2OSM. \nYou can drag the files to the panel or use the ", _interpolate(_list(0)), " buttons."])},
  "docfixmeview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rows of the reviewed files are displayed with a different color for each user who made the review. \nYou can also check it by hovering your mouse over the download link. \nDifferent users can collaborate to complete the review."])},
  "docfixmeview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 'fixmes' are designed to correct them before publishing the project. \nIts number must be reduced to 0 to be able to do so. \nThis is the list of possible problems to solve:"])},
  "docfixmeview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too large area"])},
  "docfixmeview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building with too large area. \nIf it doesn't correspond to a real building, delete it."])},
  "docfixmeview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area too small"])},
  "Select file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file"])},
  "Task Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Manager"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "docpublishview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Finally, the project is ready for publication on ", _interpolate(_list(0)), "."])},
  "docpublishview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the OSM-es organization."])},
  "docpublishview content11": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Enter the project name in English. \nFrom now on, complete the necessary fields by copying them from the template shown on the panel using the ", _interpolate(_list(0)), " buttons"])},
  "docpublishview content12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Click ", _interpolate(_list(0)), " to create the project."])},
  "docpublishview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the 'EDIT PROJECT', 'DESCRIPTION' page, select the 'Published' status. \nComplete the 'Project Name', 'Short Description' and 'Description' fields in English and Spanish."])},
  "docpublishview content14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under 'INSTRUCTIONS', complete the 'Change set comment', 'Detailed instructions', and 'Additional instructions for this task' fields in English and Spanish."])},
  "docpublishview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under 'METADATA', 'Mapping Types', select 'Buildings'."])},
  "docpublishview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the 'Save' button to finish."])},
  "docpublishview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["To do this you need to be given permission to create projects. \nSend a message requesting it to any communication channel listed in ", _interpolate(_list(0)), " indicating your username/s."])},
  "docpublishview content3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["When you have the permission assigned, use the ", _interpolate(_list(0)), " button"])},
  "docpublishview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Download file ", _interpolate(_list(0)), " ", _interpolate(_list(1)), " to your computer."])},
  "docpublishview content5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["In the 'CREATE NEW PROJECT, DEFINE AREA' window of the Task Manager click on ", _interpolate(_list(0)), " and select the downloaded zoning.zip file."])},
  "docpublishview content6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ": Activate the option for ", _interpolate(_list(1)), "."])},
  "docpublishview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define tasks using loaded polygons"])},
  "docpublishview content8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Click ", _interpolate(_list(0)), " to continue."])},
  "docpublishview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the 'REVIEW' window, check that the number of tasks displayed matches that shown in CatAtom2OSM."])},
  "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
}